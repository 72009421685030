import React from "react"
import style from "./image.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"

const Image = ({ image }) => {
  return (
    <div className={style.image}>
      <GatsbyImage alt=""  image={image.gatsbyImageData} />
    </div>
  )
}

export default Image
