import React from "react"
import style from "./intro.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import Container from "../container/container"
import Navigator from "../global/navigator"

const Intro = ({ heading, text, tags, heroImage }) => {
  return (
    <div className={style.intro}>
      <div className={style.intro__hero}>
        <GatsbyImage alt=""  image={heroImage.gatsbyImageData} />
      </div>
      <Container gutters width={"large"} pb={2}>
        <div className={style.intro__back}>
          <Navigator customPath={"/#work"}>{"< Back"}</Navigator>
        </div>
        <h1 className={style.intro__heading}>{heading}</h1>
        <div className={style.intro__split}>
          <p className={style.intro__text}>{text}</p>
          <div className={style.intro__tags}>
            <div className={style.intro__tags_wrap}>
              {tags.map((tag, index) => (
                <div key={index} className={style.intro__tag}>{tag.title}</div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Intro
