import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import { Pagination, Navigation } from "swiper"
import Slide from "./slide"
import style from "./slider.mod.scss"
import ArrowRightBlack from "../icons/arrow-right-black"
import Navigator from "../global/navigator"

const Slider = ({ slides, heading = "See more work" }) => {
  return (
    <div className={style.slider__wrap}>
      {heading && (
        <div className={style.slider__heading}>
          <h5>{heading}</h5>
        </div>
      )}
      <Swiper
        slidesPerView={1}
        slidesPerGroup={1}
        spaceBetween={30}
        simulateTouch={false}
        grabCursor={false}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{ type: "fraction" }}
        modules={[Pagination, Navigation]}
        containerModifierClass={"-simple"}
        breakpoints={{
          640: {
            slidesPerGroup: 2,
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        }}
      >
        {slides.map((item, i) => (
          <SwiperSlide key={item.id}>
            <Slide {...item} noPadding />
          </SwiperSlide>
        ))}

        <div className={style.slider__sliderNav}>
          <div className={style.slider__buttons}>
            <CarouselPrev />
            <CarouselNext />
          </div>
        </div>
      </Swiper>
      <div className={style.slider__back}>
        <Navigator customPath={"/#work"}>{"< Back"}</Navigator>
      </div>
    </div>
  )
}

export default Slider

const CarouselPrev = () => (
  <div className={style.slider__buttons_prev}>
    <div aria-label="Previous" className="swiper-button-prev">
      <div className={style.work__buttons_arrow}>
        <ArrowRightBlack />
      </div>
    </div>
  </div>
)

const CarouselNext = () => (
  <div className={style.slider__buttons_next}>
    <div aria-label="Next" className="swiper-button-next">
      <div className={style.work__buttons_arrow}>
        <ArrowRightBlack />
      </div>
    </div>
  </div>
)
