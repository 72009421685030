import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Intro from "../components/work/intro"
import Layout from "../components/global/layout"
import Container from "../components/container/container"
import Image from "../components/work/image"
import SplitImages from "../components/work/split-images"
import SupportText from "../components/work/support-text"
import Footer from "../components/footer/footer"
import Video from "../components/work/video"
import ContactSection from "../components/contact/contact"
import { Waypoint } from "react-waypoint"
import Slider from "../components/work/slider"

const WorkDetail = ({
  data: {
    datoCmsWork: {
      id,
      title,
      leadText,
      tags,
      heroImage,
      bodyImageOne,
      bodyImageTwo,
      bodyImageThree,
      bodyImageFour,
      supportText,
      supportTextFullWidth,
      video,
      seoMetaTags,
      darkHeaderLogo,
    },
    datoCmsContactPage,
    caseStudies,
  },
}) => {
  const [offset, setOffset] = useState(0)
  const [filteredCaseStudies, setFilteredCaseStudies] = useState([])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setOffset(window.pageYOffset)
      }
    }

    filterCaseStudies()
  }, [])

  const handleBackToTop = () => {
    if (typeof window !== `undefined`) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  }

  const handleLightScroll = (active = false) => {
    if (document.readyState === "complete") {
      if (active) {
        document.body.classList.add("nav--light-footer")
      } else {
        document.body.classList.remove("nav--light-footer")
      }
    }
  }

  // const filteredCaseStudies = caseStudies.nodes
  //   .filter((item) => item.title !== title)
  //   .sort((a, b) => 0.5 - Math.random())

  const filterCaseStudies = () => {
    setFilteredCaseStudies(
      caseStudies.nodes
        .filter((item) => item.title !== title)
        .sort((a, b) => 0.5 - Math.random())
    )
  }

  return (
    <>
      <Layout
        yPos={offset}
        isDarkLogo={darkHeaderLogo}
        seo={seoMetaTags}
        isNotHome={true}
        handleBackToTop={handleBackToTop}
        showBackToTop={true}
      >
        <Intro
          heading={title}
          text={leadText}
          tags={tags}
          heroImage={heroImage}
        />
        <Container gutters width={"large"} pb={1}>
          {video ? (
            <Video video={video} placeholderImage={bodyImageOne} />
          ) : (
            <Image image={bodyImageOne} />
          )}
        </Container>
        <Container gutters width={"large"} pb={2}>
          <SplitImages imageOne={bodyImageTwo} imageTwo={bodyImageThree} />
        </Container>
        <Container gutters width={"large"} pb={2}>
          <SupportText text={supportText} isFullWidth={supportTextFullWidth} />
        </Container>
        <Container gutters width={"large"} pb={1}>
          <Image image={bodyImageFour} />
        </Container>
        <Container gutters width={"large"} pt={2} pb={1}>
          <div className={"swiper-simple"}>
            <Slider slides={filteredCaseStudies} />
          </div>
        </Container>
        <Container pb={2} pt={2}>
          <ContactSection
            title={datoCmsContactPage.title}
            description={datoCmsContactPage.description}
            locations={datoCmsContactPage.locations}
          />
        </Container>

        <Waypoint
          onEnter={({ previousPosition, currentPosition, event }) => {
            if (currentPosition === "inside") {
              handleLightScroll(true)
            } else {
              handleLightScroll(false)
            }
          }}
          onLeave={({ previousPosition, currentPosition, event }) => {
            if (currentPosition !== "inside") {
              handleLightScroll(false)
            } else {
              handleLightScroll(true)
            }
          }}
        >
          <div>
            <Footer />
          </div>
        </Waypoint>
      </Layout>
    </>
  )
}

export default WorkDetail

export const query = graphql`
  query WorkDetail($id: String!) {
    datoCmsWork(originalId: { eq: $id }) {
      id: originalId
      originalId
      title
      darkHeaderLogo
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      category {
        title
      }
      tags {
        title
      }
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1920", h: "750", fit: "crop", q: 90 }
        )
        alt
      }
      bodyImageOne {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1400", h: "700", fit: "crop", q: 90 }
        )
        alt
      }
      bodyImageTwo {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "570", h: "570", fit: "crop", q: 90 }
        )
        alt
      }
      bodyImageThree {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "570", h: "570", fit: "crop", q: 90 }
        )
        alt
      }
      bodyImageFour {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1400", h: "700", fit: "crop", q: 90 }
        )
        alt
      }
      video: optionalVideo {
        providerUid
        provider
        url
      }
      leadText
      supportText
      supportTextFullWidth
    }
    datoCmsContactPage {
      id: originalId
      title
      description
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      locations {
        title
      }
    }
    caseStudies: allDatoCmsWork(sort: { order: ASC, fields: position }) {
      nodes {
        position
        title
        category {
          title
        }
        excerpt
        id: originalId
        heroImage {
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { w: "420", h: "240", fit: "crop", q: 90 }
          )
          alt
        }
      }
    }
  }
`
