import React from "react"
import style from "./support-text.mod.scss"

const SupportText = ({ text, isFullWidth }) => {
  return (
    <div className={style.support}>
      <div></div>
      <p>{text}</p>
    </div>
  )
}

export default SupportText
