import React from "react"
import style from "./split-images.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"

const SplitImages = ({ imageOne, imageTwo }) => {
  return (
    <div className={style.images}>
      <GatsbyImage alt=""  image={imageOne.gatsbyImageData} />
      <GatsbyImage alt=""  image={imageTwo.gatsbyImageData} />
    </div>
  )
}

export default SplitImages
