import React, { useState } from "react"
import style from "./video.mod.scss"
import ModalVideo from "react-modal-video"
import { GatsbyImage } from "gatsby-plugin-image"

const Video = ({ video, placeholderImage }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <div className={style.video}>
      <div className={style.video__placeholder} onClick={() => setOpen(true)}>
        <GatsbyImage alt="" 
          className={style.video__placeholder_gatsby}
          image={placeholderImage.gatsbyImageData}
          alt={
            placeholderImage.alt ? placeholderImage.alt : "Placeholder Image"
          }
        />
      </div>
      <div className={style.video__icon} onClick={() => setOpen(true)}>
        <PlayIcon />
      </div>
      <ModalVideo
        channel={video.provider}
        autoplay
        isOpen={isOpen}
        videoId={video.providerUid}
        onClose={() => setOpen(false)}
      />
    </div>
  )
}

const PlayIcon = () => {
  return (
    <div className={style.video__icon_play}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
        <circle
          data-name="Ellipse 162"
          cx="45"
          cy="45"
          r="45"
          fill="#edfbfd"
          opacity=".4"
        />
        <circle
          data-name="Ellipse 1"
          cx="40"
          cy="40"
          r="40"
          transform="translate(5 5)"
          fill="#fff"
        />
        <path d="m57.334 45.5-17.333 10v-20z" />
      </svg>
    </div>
  )
}

export default Video
